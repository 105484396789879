import React, { useState, useEffect } from "react";
import { Container, Col, Row, Image } from "react-bootstrap";
import SEO from "../../components/shared/seo";
import { FadeUpBox, FadeIn } from "../../components/shared/transitions";
import {
  OverlayFill,
  OverlayFillGradient,
} from "../../components/shared/overlay";
import { useStaticQuery, graphql } from "gatsby";
import { CaseStudyNav } from "../../components/shared/case-study-nav";
import { Controller, Scene } from "react-scrollmagic";
import LayoutCaseStudy from "../../components/layouts/layout-case-study";
import { ParallaxBanner } from "react-scroll-parallax";
import { QuoteBlock } from "../../components/shared/quote-block";
import { Helmet } from "react-helmet";

function EnergyActionPage({ location }) {
  // const { state = {} } = location;
  const [animate, setAnimate] = useState(false);
  const eaImages = useStaticQuery(graphql`
    query {
      eaBanner: file(relativePath: { eq: "ea-electricity.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 70) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      eaMockup: file(relativePath: { eq: "ea-mac-mockup.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 70) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      eaMockup2: file(relativePath: { eq: "ea-mac-mockup-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 70) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      eaDashboard: file(relativePath: { eq: "ea-dashboard.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 70) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      prevWorkImage: file(relativePath: { eq: "murdoch-banner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 70) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      nextWorkImage: file(relativePath: { eq: "stjohn-page-banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      sitefinityLogo: file(relativePath: { eq: "sitefinity-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      eaLogoSm: file(relativePath: { eq: "ea-logo-sm.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      energyActionMock: file(relativePath: { eq: "energy-action-mockup.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      energyActionDecorative: file(
        relativePath: { eq: "energy-action-decorative.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimate(true);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "Diviv Group",
            "description": ".NET applications, Progress Sitefinity CMS, Drupal, GovCMS, Sitecore Experience Platform, Microsoft Sharepoint, Dynamics CRM and Azure Hosting support in Perth Australia. Coupled with our creative and user experience team we are able to provide expert consultation and resources on a wide range of digital development projects across many industries.",
            "url": "https://www.diviv.com.au",
            "image": "https://www.diviv.com.au/icons/icon-96x96.png",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "165 / 15 Aberdeen St",
              "addressLocality": "Perth",
              "addressRegion": "WA",
              "postalCode": "6000",
              "addressCountry": "AU"
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "5",
              "ratingCount": "1"
            },
            "review": {
              "@type": "Review",
              "author": "Michael Johnson",
              "datePublished": "2020-03-09",
              "reviewBody": "Diviv is a solution based partner that delivers front and back end website solutions with innovation, professionalism and integrity. They are a great team and a pleasure to work with.",
              "reviewRating": {
                "@type": "Rating",
                "bestRating": "5",
                "ratingValue": "5",
                "worstRating": "1"
              }
            }
          }
          `}
        </script>
      </Helmet>
      <SEO
        title="Energy Action"
        keywords="Diviv our work case studies"
        description="Diviv has extensive experience resolving complex digital problems for businesses in a wide variety of industries. Refer to our case studies to understand how we have delivered successful business outcomes for businesses like yours."
      />
      <LayoutCaseStudy>
        <div
          id="energy-action-content"
          className="page-layout"
          data-menu-class="inverted"
        >
          <Container>
            <Row>
              <Col sm={12} lg={8}>
                <div className="client-logo--lg mb-4">
                  <Image src={eaImages.eaLogoSm.childImageSharp.fluid.src} />
                </div>
                <FadeUpBox pose={animate ? "enter" : "exit"}>
                  <FadeIn>
                    <h6>Web App Development</h6>
                  </FadeIn>
                  <FadeIn duration={200}>
                    <h2>Energy Action Portal</h2>
                  </FadeIn>
                  <FadeIn duration={200}>
                    <p>
                      Energy Action is a leading supplier of energy procurement,
                      contract management, energy reporting and energy advisory
                      services with over 10,000 commercial clients
                      Australia-wide.
                    </p>

                    <p>
                      Energy Actions Metrics Portal is a reporting product for
                      large scale energy buyers to track power consumption
                      across all assets in their portfolio. The portal gives
                      granular and executive level reporting adding
                      significantly valuable data and insight to their customer
                      base.
                    </p>
                    <p>
                      We have supported, maintained and improved Energy Action’s
                      Metrics portal since 2016. At the end of 2018 Diviv
                      embarked on a complete refresh project which included two
                      new product levels and a whole new dashboard experience.
                    </p>
                  </FadeIn>
                </FadeUpBox>
              </Col>

              <Col sm={12} lg={4}>
                <FadeUpBox pose={animate ? "enter" : "exit"}>
                  <FadeIn>
                    <div className="client-meta">
                      Discover what else Energy Action is up to by visiting
                      their website:
                      <div className="client-meta__link">
                        <a
                          href="https://www.energyaction.com.au/"
                          target="_blank"
                        >
                          Energy Action
                        </a>
                      </div>
                    </div>
                  </FadeIn>
                </FadeUpBox>
              </Col>
            </Row>
          </Container>

          <Controller>
            <Scene
              triggerElement="#intro-section01"
              reverse={false}
              duration={2000}
              offset={200}
            >
              {(progress, event) => (
                <section className="image-mockup-section" id="intro-section01">
                  <Container>
                    <Row className="intro-section__image-mock-up">
                      <Col lg={12} sm={12}>
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn duration={200}>
                            <Image
                              src={
                                eaImages.energyActionMock.childImageSharp.fluid
                                  .src
                              }
                            />
                          </FadeIn>
                        </FadeUpBox>
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}
            </Scene>

            <Scene
              triggerElement="#intro-section02"
              reverse={false}
              duration={2000}
              offset={200}
            >
              {(progress, event) => (
                <section id="intro-section02">
                  <Container>
                    <Row>
                      <Col lg={6} sm={12} className="text-section">
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <div className="mb-5">
                              <h4>The Problem</h4>
                              <p>
                                The metrics portal originally designed and built
                                in 2014, at that time it presented the latest
                                web technologies but from the perspective of
                                modern useability standards the portal had
                                started to age. Energy Action were also looking
                                to introduce a new Small Market Energy (SME)
                                product so the time was right to look at
                                rebuilding the portal with the focus on
                                responsiveness, usability and speed.
                              </p>
                            </div>

                            <h4>The Solution</h4>
                            <p>
                              We helped design and build a highly sophisticated
                              client web portal for the customers of Energy
                              Action to access in-depth analysis power
                              consumption data.
                            </p>
                            <p>
                              This portal has allowed Energy Action to remain
                              the leader in its field in Australia, they are a
                              proud foundation client and we hope to continue to
                              help them grow into the future.
                            </p>
                          </FadeIn>
                        </FadeUpBox>
                      </Col>

                      <Col lg={6} sm={12}>
                        <div className="tagline">
                          <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                            <FadeIn>
                              Oh, that feeling you get when you interact with a
                              great digital product!
                            </FadeIn>
                          </FadeUpBox>
                        </div>

                        <div className="img-wrapper">
                          <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                            <FadeIn>
                              <Image
                                src={
                                  eaImages.energyActionDecorative
                                    .childImageSharp.fluid.src
                                }
                              />
                            </FadeIn>
                          </FadeUpBox>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}
            </Scene>
          </Controller>
        </div>

        <Scene
          triggerElement="#intro-section"
          reverse={false}
          duration={2000}
          offset={200}
        >
          {(progress, event) => (
            <section className="quote-wrapper">
              <Container>
                <QuoteBlock
                  author="Michael Johnson"
                  authorPosition="Senior Business Analyst"
                  quoteText="Diviv is a solution based partner that delivers
                              front and back end website solutions with
                              innovation, professionalism and integrity. They
                              are a great team and a pleasure to work with."
                  clientLogoUrl={eaImages.eaLogoSm.childImageSharp.fluid.src}
                />
              </Container>
            </section>
          )}
        </Scene>

        <CaseStudyNav
          nextUrl="/our-work/stjohn-ambulance/beats/"
          nextImage={eaImages.nextWorkImage.childImageSharp.fluid.src}
          nextTitle="BEATS St John Ambulance"
        />
      </LayoutCaseStudy>
    </>
  );
}

export default EnergyActionPage;
